import React from 'react'
import TestComponent from './components/PaymentsChannelsInfo'
import "./Styles.css"

export default function PaymentsInfo () {
  return (
    <>
        <TestComponent/>
    </>
  )
}



import React from "react"
import Parallax from "components/material-kit-pro-react/components/Parallax/Parallax.js"
import { useStaticQuery, graphql } from "gatsby"
import SectionTemplate from "LandingPageMaterial/Views/Sections/SectionTemplate"
import { isIOS, isAndroid } from "react-device-detect"

// MUI components
import { styled } from "@mui/material/styles"

import GridContainer from "components/material-dashboard-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-dashboard-pro-react/components/Grid/GridItem.js"

import sectionStyle from "LandingPageMaterial/Views/Sections/sectionStyle"
import LandingPage from "LandingPageMaterial/Layout/LandingPage"
import PaymentsInfo from "../Portal/Views/OnlinePayments/paymentChannels/PaymentsInfo"
import PaymentsChannelsInfo from "../Portal/Views/OnlinePayments/paymentChannels/components/PaymentsChannelsInfo"

const NewDiv = styled("div")(({ theme, ownerState }) => {
  const { style } = ownerState
  return { ...sectionStyle[style] }
})

export default function Info() {
  const data = useStaticQuery(
    graphql`
      {
        allStrapiNoticias: allStrapiNoticia(
          sort: { order: DESC, fields: fecha_publicacion }
          filter: {
            categoria_publicacion: { identificador_categoria: { eq: "1" } }
          }
        ) {
          edges {
            node {
              id
              autor
              titulo_noticia
              cuerpo_noticia {
                data {
                  cuerpo_noticia
                }
              }
              area_seguro_noticia {
                nombre_area_seguro
              }
              imagen_principal {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 95, layout: FULL_WIDTH)
                  }
                }
              }
              imagen_alterna {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      width: 700
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
        allStrapiAssetsMedias: allStrapiAssetsMedia(
          filter: { codigo: { eq: "NOTICIAS_BACKGROUND" } }
        ) {
          edges {
            node {
              archivo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 95, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const noticias = data.allStrapiNoticias.edges
  const backgroundImage =
    data.allStrapiAssetsMedias.edges[0].node.archivo.localFile.childImageSharp
      .gatsbyImageData.images.fallback.src

  const NewDiv = styled("div")(({ theme, ownerState }) => {
    const { style } = ownerState
    return { ...sectionStyle[style] }
  })
  const NewH1 = styled("h1")(({ theme, ownerState }) => {
    const { style } = ownerState
    return { ...sectionStyle[style] }
  })

  return (
    <LandingPage color="white" pageTitle="Realización de Pagos">
      {!isAndroid && !isIOS ? (
        <Parallax small image={backgroundImage}>
          <NewDiv
            ownerState={{
              style: "container",
            }}
          ></NewDiv>
        </Parallax>
      ) : null}
      {isAndroid || isIOS ? <div style={{ marginTop: "120px" }} /> : null}
      <SectionTemplate>
        <GridContainer>
          <PaymentsChannelsInfo />
        </GridContainer>
      </SectionTemplate>
      {isAndroid || isIOS ? <div style={{ marginBottom: "50px" }} /> : null}
    </LandingPage>
  )
}

import React from "react"
import "./Styles.css"
import CustomDropdownWithPush from "./CustomDropdownWithPush "

const Buttons = ({ groups, onImagenSeleccionada, selectedCode }) => {
  return (
    <div style={{display: "flex", flexDirection: "column", gap: "10px 30px"}}>
      {groups.map((item, index) => {
        if (item.COMPONENT_TYPE === "dropdown") {
          return (
            <CustomDropdownWithPush
              key={item.CODGRUPOCPGO}
              buttonText={item.DESCGRUPOCPGO}
              dropdownList={item.children.map((itm) => {
                return {
                  label: itm.DESCCANALPAGO,
                  code: itm?.CODGRUPOCPGO + itm?.CODCANALPAGO,
                  onClick: () => onImagenSeleccionada(itm),
                }
              })}
              index={index}
              selectedCode={selectedCode}
            />
          )
        } else if (item.COMPONENT_TYPE === "button") {
          return (
            <div
              key={item.CODGRUPOCPGO}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="btn" onClick={() => onImagenSeleccionada(item)}>
                {item.DESCCANALPAGO}
              </div>
            </div>
          )
        }
        return null
      })}
      <div
        key={"btn-init"}
        style={{
          display: "flex",
          justifyContent: "center",
          textTransform: "uppercase",
        }}
      >
        <div
          className="btn"
          onClick={() =>
            onImagenSeleccionada({
              URL: `${process.env.GATSBY_API_URL}/metodosDePago.png`,
            })
          }
        >
          Notificar Pago
        </div>
      </div>
      <div
        key={"btn-out"}
        style={{
          display: "flex",
          justifyContent: "center",
          textTransform: "uppercase",
        }}
      >
        <div className="btn" onClick={() => (window.location.href = "/")}>
          Ir al Inicio
        </div>
      </div>
    </div>
  )
}

export default Buttons

import React, { useState, useEffect } from "react"
import "./CustomDropdownWithPush.css"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"

const CustomDropdownWithPush = ({
  buttonText,
  dropdownList,
  index,
  selectedCode,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    if (index === 0) {
      setIsOpen(true)
    }
  }, [])

  return (
    <div className="dropdown-container">
      <div className="dropdown-button" onClick={toggleDropdown}>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          {buttonText}
        </div>
        <div
          style={{ textAlign: "end", display: "flex", alignItems: "center" }}
        >
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {dropdownList.map((item, index) => {
            return (
              <div
                key={index}
                onClick={item.onClick}
                className={
                  item?.code === selectedCode
                    ? "dropdown-item selected"
                    : "dropdown-item"
                }
              >
                {item.label}
                <hr
                  style={{
                    border: "solid 1px #d3d6db",
                    marginBottom: "-0.5rem",
                  }}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CustomDropdownWithPush
